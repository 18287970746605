* {
  padding: 0;
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  outline: none;
  list-style: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
}

html,
body,
#root {
  min-height: 100%;
  height: 1px;
}
html {
  font-family: 'Inter', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}

.app {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.main-content {
  padding: 30px 0 80px;
  flex-grow: 1;
  min-height: calc(100vh - 357px);
}

.secondary-background {
  background-color: #f8f8f8;
}

@media (max-width: 768px) {
  .main-content {
    padding: 30px 0 80px;
  }
}

/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/inter/inter-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/inter/inter-v8-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/inter/inter-v8-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/inter/inter-v8-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/inter/inter-v8-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/inter/inter-v8-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-600 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/inter/inter-v8-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/inter/inter-v8-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/inter/inter-v8-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/inter/inter-v8-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/inter/inter-v8-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/inter/inter-v8-latin-600.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/inter/inter-v8-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/inter/inter-v8-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/inter/inter-v8-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/inter/inter-v8-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/inter/inter-v8-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/inter/inter-v8-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-800 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/inter/inter-v8-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/inter/inter-v8-latin-800.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/inter/inter-v8-latin-800.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/inter/inter-v8-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/inter/inter-v8-latin-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/inter/inter-v8-latin-800.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-900 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/inter/inter-v8-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/inter/inter-v8-latin-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/inter/inter-v8-latin-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/inter/inter-v8-latin-900.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/inter/inter-v8-latin-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/inter/inter-v8-latin-900.svg#Inter') format('svg'); /* Legacy iOS */
}

/* mulish-regular - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/mulish/mulish-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/mulish/mulish-v10-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/mulish/mulish-v10-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/mulish/mulish-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/mulish/mulish-v10-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/mulish/mulish-v10-latin-regular.svg#Mulish')
      format('svg'); /* Legacy iOS */
}
/* mulish-500 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/mulish/mulish-v10-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/mulish/mulish-v10-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/mulish/mulish-v10-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/mulish/mulish-v10-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/mulish/mulish-v10-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/mulish/mulish-v10-latin-500.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-600 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/mulish/mulish-v10-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/mulish/mulish-v10-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/mulish/mulish-v10-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/mulish/mulish-v10-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/mulish/mulish-v10-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/mulish/mulish-v10-latin-600.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-700 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/mulish/mulish-v10-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/mulish/mulish-v10-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/mulish/mulish-v10-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/mulish/mulish-v10-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/mulish/mulish-v10-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/mulish/mulish-v10-latin-700.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-800 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/mulish/mulish-v10-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/mulish/mulish-v10-latin-800.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/mulish/mulish-v10-latin-800.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/mulish/mulish-v10-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/mulish/mulish-v10-latin-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/mulish/mulish-v10-latin-800.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-900 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/mulish/mulish-v10-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/mulish/mulish-v10-latin-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/mulish/mulish-v10-latin-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/mulish/mulish-v10-latin-900.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/mulish/mulish-v10-latin-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/mulish/mulish-v10-latin-900.svg#Mulish') format('svg'); /* Legacy iOS */
}
